.order-products-input-comment .input-field {
    display: flex;
    width: 100%;
}

.order-products-input-comment .label {
    font-size: 15px;
}

.chat-body {
    margin: 10px;
    height: 350px;
    overflow: auto;
    display: flex;
    flex-direction: column-reverse;
}

.sender {
    display: flex;
    justify-content: flex-end;
}

.receiver {
    display: flex;
}

.receiver-body {
    display: flex;
    flex-direction: column;
    width: 60%;
    text-align: left;
    position: relative;
    margin-left: 20px;
    margin-bottom: 10px;
}

.message-blue {
    position: relative;
    margin-left: 20px;
    margin-bottom: 10px;
    /*margin-top: 10px;*/
    padding: 10px;
    background-color: #75bee5;
    width: 60%;
    text-align: left;
    border-radius: 10px;
}

.message-orange {
    border-radius: 10px;
    /*margin-top: 10px;*/
    padding: 10px;
    background-color: #d87e09;

}

.message-content {
    padding: 0;
    margin: 0 0 10px 0;
}

.message-time-receiver {
    position: absolute;
    font-size: 12px;
    font-weight: 300;
    bottom: 3px;
    right: 5px;
}

.message-name {
    margin-left: 20px;
}

.form {
    display: flex;
    justify-content: right;
    width: 95%;
    margin: auto;
}

.message-text {
    width: 100% !important;
}

.statusList {
    display: flex;
    flex-direction: column;
    font-size: 18px;
}

