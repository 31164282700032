body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
#Forms .checkbox {
  position: initial !important;
}

#Forms .head-checkbox label {
  white-space: normal;
}

#Forms .col-telephone-number {
  position: sticky;
  left: 0;
  background-color: #fff;
  z-index: 1;
}

#Forms .rt-tbody .rt-tr-group:first-child .col-telephone-number {
  box-shadow: inset 0 10px 17px -11px rgba(0, 0, 0, .2);
}

.input-field [type="checkbox"] + label {
  top: -12px !important;
}

.ReactVirtualized__Table.striped .ReactVirtualized__Table__row:nth-child(odd) {
  background: rgba(0, 0, 0, .03);
}

.ReactVirtualized__Table__headerTruncatedText, .ReactVirtualized__Table__headerColumn {
  font-size: 15px;
  text-transform: capitalize;
  font-weight: normal;
  font-family: sans-serif;
  cursor: pointer;
  white-space: nowrap;
}

.ReactVirtualized__Table__headerRow {
  margin-bottom: 4px;
}

.ReactVirtualized__Table__sortableHeaderIcon {
  display: none;
}

.e-float-input.e-input-group.e-control-wrapper.e-numeric .e-numeric-hidden,
.e-input-group.e-control-wrapper.e-numeric .e-numeric-hidden,
.e-float-input.e-control-wrapper.e-numeric .e-numeric-hidden,
.e-float-input.e-input-group.e-control-wrapper.e-numeric.e-input-focus .e-numeric-hidden,
.e-input-group.e-control-wrapper.e-numeric.e-input-focus .e-numeric-hidden,
.e-float-input.e-control-wrapper.e-numeric.e-input-focus .e-numeric-hidden {
  display: none;
}

.react-datepicker__input-container input {
  margin: 0 !important;
}

.checkbox-active::before {
  border-right: 5px solid #26a69a !important;
  border-bottom: 5px solid #26a69a !important;
}

.MuiFormControl-root .MuiInputBase-root.MuiOutlinedInput-root {
  padding-right: 0 !important;
}

.MuiFormControl-root .MuiInputBase-root input {
  margin-bottom: 0 !important;

}
