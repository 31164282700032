.form-section {
  position: relative;
  margin-bottom: 20px;
}

.form-section > .row {
  margin-bottom: 0;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
}