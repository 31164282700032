.manualServiceAddWrapper .top-of-modal-container {
    margin-bottom: 50px;
}

.manualServiceAddWrapper .top-of-modal-container .row {
    margin-bottom: 30px;
}

.manualServiceAddWrapper .middle-of-modal-container {
    margin-bottom: -20px;
}

.manualServiceAddWrapper .middle-of-modal-container .row {
    margin-bottom: -10px;
}

.manualServiceAddWrapper .bottom-of-modal-container .row {
    margin-bottom: 0;
}


.manualServiceAddWrapper .bottom-of-modal-container .bottom-checkboxes-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 50px;
}

.manualServiceAddWrapper .bottom-modal-euro-title-container {
    margin: 50px 0 50px 15px;
}

.manualServiceAddWrapper .bottom-of-modal-container .upper-checkboxes-container {
    display: flex;
    width: 100%;
    height: 15vh;
    flex-direction: column;
    justify-content: space-between;
}

.manualServiceAddWrapper .bottom-of-modal-container .upper-checkboxes-container .checkboxes-group {
    display: flex;
    flex-direction: column;
    gap: 7px;
    width: 100%;
}

.manualServiceAddWrapper .bottom-of-modal-container-partner-services .row {
    margin-bottom: 0;
}