.calculation-label-container {
    display: flex;
    flex-direction: column;
    width: 75%;
}

.calculation-label-container .label {
    margin-left: 4px;
    color: red;
}

.calculation-label-container .custom-input {
    margin-bottom: 10px;
    margin-top: 10px;
}
