@charset "utf-8";
/* Dirty quick fix */
.input-field label {
    top: -30px !important;
    font-size: 0.8rem !important;
}
.input-field label:not(.label-icon).active {
    -webkit-transform: translateY(13px) scale(1);
    transform: translateY(13px) scale(1);
}
.reactnopaddingmargin {
	margin-bottom: 0;
	padding-top: 20px;
}
.reactnopaddingmargin .input-field {
	margin-left:0;
	padding-left:0;
}
/* CSS Document */
i.tiny {
    font-size: 1.3rem;
}
i.glyphicon.tiny.material-icons {
    font-size: 1rem;
}

@media (max-width: 600px) {
	.valign-wrapper {
		padding: 40px 0;
	}
}

.verticalvalign {
	height: 100%;
	width: 100%;
	margin-top: 7.5%;
}
.btn.disabled, .disabled.btn-large,
.btn-floating.disabled,
.btn-large.disabled,
.btn-flat.disabled,
.btn:disabled,
.btn-large:disabled,
.btn-floating:disabled,
.btn-large:disabled,
.btn-flat:disabled,
.btn[disabled],
[disabled].btn-large,
.btn-floating[disabled],
.btn-large[disabled],
.btn-flat[disabled] {
  border-color: #DFDFDF !important;
}
.btn {
    overflow: hidden;
    text-overflow: "...";
}
.valign{
	width:100%;
	height: 100%;
}
.alignright {
	text-align: right;
}
.alignleft {
	text-align: left;
}

.nopadding {
	padding: 0 !important;
}
.paddingbottomnone {
	padding-bottom:0 !important;
}
.paddingbottomnonetop50px {
	padding-bottom:0 !important;
	padding-top:50px !important;
}
.paddingtop50px {
	padding-top:50px !important;
}
.alignleft {
	text-align: left;
}
.margintop10 {
	margin-top: 10px;
}
.margintop20 {
	margin-top: 20px;
}
.marginbottom30 {
	margin-bottom: 30px;
}
.marginright15 {
	margin-right:15px;
}
.margingone{
	margin: 0;
}
.bulkupload {
	width: 100%;
	height: 54px;
	border-width: 2px;
	border-style: dashed;
	border-radius: 5px;
	padding: 15px;
	color: #d1d1d1;
	margin-bottom: 30px;
	white-space: nowrap;
	font-size: 15px;
}
h1 {
	font-size: 1.4em;
    margin: 2.1rem 0 2.1rem 0;
}
h4 {
    padding: 5px 0 5px 0px;
    margin: 0 0 5px;
    display: inline-block;
	font-size: 1.4em;
}
h5 {
    padding: 0 0 5px;
    margin: 0 0 5px;
    display: inline-block;
	font-size: 1.8em;
}
@media (max-width: 993px) {
	h5 {
		font-size: 1.2em;
	}
}

h6 {
    padding: 0;
    margin: 0 0 15px;
	opacity: 0.8;
}
.h1-margin {
    margin: 2.1rem 0 2.1rem 0;
}

.admin-top-buttons {
	display: inline-flex;
	justify-content: flex-end;
}

.admin-top-buttons > .doceri-btn-right {
	margin-left: 10px;
}

.admin-subheader-text {
	display: inline-block !important;
}

.admin-subheader-button {
	margin: 1.5rem 1.5rem 0 0;
}

.technical-person,
.project-manager-person {
	display: flex;
}

.tussenkop {
	font-style: italic;
	padding-top: 5px;
	opacity: 0.8;
}
.overviewkop {
	font-style: normal;
	padding: 15px 0;
	color: #4c4c4c;
	font-size: 1.2em;
}
.blokborder{
	border-width: 0 1px 1px 1px !important;
	border-radius: 0 0 4px 4px;
	-moz-border-radius: 0 0 4px 4px;
	padding-top: 20px !important;
}
.blokbordertable{
	border-width: 0 2px 1px 1px !important;
	border-radius: 0 0 4px 4px;
	-moz-border-radius: 0 0 4px 4px;
	padding-top: 20px !important;
}
.flex {
    display:flex;
}
.overviewkopbgtotal {
	border-radius: 4px;
	-moz-border-radius: 4px;
	margin-bottom: 15px;
	padding: 15px !important;
}
.overviewkopbgalleen {
	border-radius: 4px;
	-moz-border-radius: 4px;
	margin-bottom: 15px;
}
.highlight {
	margin-bottom: 15px !important;
}
thead {
    border-bottom: 0;
}
thead th {
	padding-top: 15px;
	padding-bottom: 0;
	border-right:1px solid #fff;
}
th, td {
	padding-left: 15px;
}
td {
	border-right:1px solid #EDEDED;
}
.bodynormal{
	color: #333;
	font-size: 0.8em;
}
.subthread {
	font-style: italic;
	font-weight: 400 !important;
	font-size: 1em;
	padding-top: 0px;
	padding-bottom: 15px;
}

.threadpad {
	padding-bottom: 15px;
}

/* Containers*/
.maincontainer {
	height: auto;
	padding: 0 2.25rem;
}
.container {
  	margin: 0 auto;
	max-width: 2000px;
  	width: auto !important;
}

.digital-signing-table-column-description {
	width: 35%;
}

.canvas-signatures-section {
	display: flex;
	justify-content: flex-end;
}

@media only screen and (min-width: 601px) {
  	.container {
    	width: 90%;
  	}
	.maincontainer {
		padding: 35px 30px;
	}
}

@media only screen and (min-width: 1200px) {
	.digital-signing-content {
		display: flex;
	}

	#download-offerte-attachment {
		margin-right: 20px;
	}
}

@media only screen and (max-width: 1200px) {
	#download-offerte-attachment {
		margin-right: 20px;
	}
}

@media only screen and (max-width: 860px) {
	.canvas-signatures {
		width: 680px;
		height: 200px;
	}

	.canvas-signatures-section {
		justify-content: flex-start;
		padding: 0 !important;
	}
}

@media only screen and (max-width: 830px) {
	.canvas-signatures {
		width: 600px;
	}

	.save-signature-button {
		float: left !important;
		margin: 0 20px 20px 0 !important;
	}

	.delete-signature-button {
		float: left !important;
	}
}

@media only screen and (max-width: 780px) {
	.canvas-signatures {
		width: 540px;
	}
}

@media only screen and (max-width: 730px) {
	.canvas-signatures {
		width: 500px;
	}
}

@media only screen and (max-width: 700px) {
	.canvas-signatures {
		width: 450px;
	}

	.digital-signing-table-column-description {
		width: 200px;
	}
	.digital-signing-table-column-amount {
		width: 100px;
	}

	.digitalSigningTable > div > div {
		overflow: visible!important;
	}

	.digital-signing-menu-container {
		float: right !important;
	}

	.digital-signing-menu-data {
		display: none;
	}

	.logo-large-digital-signing {
		width: 200px;
	}
}

@media only screen and (max-width: 635px) {
	.canvas-signatures {
		width: 400px;
	}
}

@media only screen and (max-width: 580px) {
	.canvas-signatures {
		width: 350px;
	}
}

@media only screen and (max-width: 540px) {
	.canvas-signatures {
		width: 300px;
	}
}

@media only screen and (max-width: 520px) {
	.download-buttons {
		display: inline-grid;
	}

	#download-offerte-pdf {
		margin-bottom: 20px;
	}
}

@media only screen and (max-width: 495px) {
	.digital-signing-form-content, .digital-signing-form-signature {
		padding: 0 20px !important;
	}
}

@media only screen and (max-width: 453px) {
	.save-signature-button {
		float: left !important;
		margin: 0 20px 20px 0 !important;
	}

	.delete-signature-button {
		float: left !important;
	}
}

@media only screen and (max-width: 450px) {
	.canvas-signatures {
		width: 280px;
	}
}

@media only screen and (max-width: 430px) {
	.canvas-signatures {
		width: 260px;
	}
}

@media only screen and (max-width: 400px) {
	.canvas-signatures {
		width: 240px;
	}
}

@media only screen and (min-width: 993px) {
  	.container {
    	width: 90%;
  	}
	.maincontainer {
		padding: 35px 30px;
	}
	#Menu{
		padding: 0;
	}
	.hide-large {display: none}
	.menuhide {
		padding-bottom: 0;
	}
}
@media (max-width: 993px) {
	.menuhide {display: none}
	.hide-small {display: none}
	#Steps {
		/*margin-top: 20px;*/
	}
}

@media (max-width: 461px) {
	#Steps .steps-title {
		margin-bottom: 20px;
	}
}

.container .row {
  	margin-left: 0rem;
  	margin-right: 0rem;
}
#Forms {
	padding: 0 15px 30px 15px;
}
.Forms {
	padding: 0 15px 30px 15px;
	border: 1px solid #FED001;
	border-radius: 4px;
	-moz-border-radius: 4px;
	margin-bottom: 20px;
	background-color: white;
}
.digitalSigningForm {
	padding:  20px 40px 40px 40px;
}
#Locations {
	overflow:	hidden;
}
#Locations, #Forms {
  	border: 1px solid;
	border-radius: 4px;
	-moz-border-radius: 4px;
	margin-bottom: 20px;
	background-color: white;
}
@media screen and (min-width: 1093px) {
	#Locations {
		margin-bottom: 40px;
	}
}
#Locationfilters {
	padding:20px 15px 5px;
}
#Locationsearch{
	margin-bottom: 15px;
	padding: 15px;
	border-bottom: 1px solid;
}
#Locationslist{
	clear: both;
	max-height: 315px;
	padding: 0 15px 15px 15px;
	overflow-y: auto;
}
#Locationslist ul li:hover {
	border-radius: 4px;
	-moz-border-radius: 4px;
}

.section {
    padding-top: 0rem;
    padding-bottom: 0rem;
}

/* Logo */
.logo-large, .logo-large-digital-signing {
	min-height: 100px;
	padding: 10px;
	border-radius: 4px;
	-moz-border-radius: 4px;
	text-align: center;
	border: 1px solid;
	background-color: #ffffff;
	margin-bottom: 20px;
}
@media (max-width: 993px) {
	.logo-large, .logo-large-digital-signing {
		min-height: 30px;
	}
}
.logincontainer {
	max-width: 30%;
	background-color: #FFFFFF;
	border-radius: 4px;
	-moz-border-radius: 4px;
  	border-width: 1px;
  	border-style: solid;
	text-align: center;
	padding: 50px;
	/* -webkit-clip-path: polygon(50% 0%, 100% 0, 100% 75%, 85% 100%, 0 100%, 0 0);
	clip-path: polygon(50% 0%, 100% 0, 100% 75%, 85% 100%, 0 100%, 0 0);*/
}
@media (max-width: 1025px) {
	.logincontainer {
		max-width: 60%;
		padding: 20px;
	}
}

.logocontainer-login{
	margin-bottom: 50px;
}

.popupcontainer {
	max-width: 100%;
	background-color: #FFFFFF;
  	border-width: 1px;
  	border-style: solid;
	text-align: center;
	padding: 15px;
}

/* navigation */
nav .nav-wrapper i {
	padding-left: 15px;
}

.nav-wrapper .right { margin-right: 50px; }
.nav-wrapper .right.icons .material-icons { display: inline-block; margin-left: 10px; }
.slide-menu-icon { float: left; padding: 10px 10px 0 0; }

.company-title {
	font-size: 1.2rem;
}

.company-title-yielder {
	font-size: 1.2rem;
	color: white;
}

.quotation-title {
	color: rgb(88, 88, 90);
	font-size: 1.4em;
}

.quotation-title-yielder {
	color: white;
	font-size: 1.4em;
}

@media (min-width: 872px) {
	.app-header-logo {
		height: 100px !important;
	}
}

@media (min-width: 300px) {
	.app-header-logo-digital-signing {
		height: 100px !important;
	}
}

/* Icons */
.btn i, .btn-large i, .btn-floating i, .btn-large i, .btn-flat i {
    font-size: 1rem;
}
i.left {
    margin-right: 5px;
}
i.right {
    margin-left: 5px;
}

/* Buttons */

.btn, .btn-large, .btn-flat {
    border-radius: 4px;
	-moz-border-radius: 4px;
    height: 3rem;
    line-height: 3rem;
}
.doceri-btn {
	box-shadow: none !important;
	text-transform: none;
	padding: 0 15px;
	border-radius: 4px;
	-moz-border-radius: 4px;
	text-align: left;
	background-color: white !important;
}

.doceri-btn-right{
	box-shadow: none !important;
	text-transform: none;
	padding: 0 15px;
	border-radius: 4px;
	-moz-border-radius: 4px;
	text-align: left;
	/*-webkit-clip-path: polygon(50% 0%, 100% 0, 100% 40%, 91% 100%, 0 100%, 0 0);
	clip-path: polygon(50% 0%, 100% 0, 100% 40%, 91% 100%, 0 100%, 0 0);*/
	float: right;
}

.doceri-btn-switch{
	box-shadow: none !important;
	text-transform: none;
	border-radius: 4px;
	-moz-border-radius: 4px;
	text-align: left;
	margin-bottom: 5px;
}

.btn-switch-section {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.doceri-btn-margin-right{
	margin-right: 10px;
}

.quotation-btns-inline {
	display: flex;
	float: right;
}

.doceri-btn-left {
	box-shadow: none !important;
	text-transform: none;
	padding: 0 15px;
	border-radius: 4px;
	-moz-border-radius: 4px;
	text-align: left;
	/*-webkit-clip-path: polygon(50% 0%, 100% 0, 100% 40%, 91% 100%, 0 100%, 0 0);
	clip-path: polygon(50% 0%, 100% 0, 100% 40%, 91% 100%, 0 100%, 0 0);*/
	float: left;
}

/* Forms */

/* enable absolute positioning */
.inner-addon {
    position: relative;
}

/* style icon */
.inner-addon .glyphicon {
  position: absolute;
  padding-left: 10px;
  top:17px;
  pointer-events: none;
}

.ratiotable .inner-addon .glyphicon {
  position: absolute;
  padding-left: 10px;
  pointer-events: none;
}


.inner-addon.in-col .glyphicon {
	z-index: 1;
	left: 22px;
}



/* align icon */
.left-addon .glyphicon  { left:  0px;}
.right-addon .glyphicon { right: 0px;}

/* add padding  */
.left-addon input  { padding-left:  30px; }
.right-addon input { padding-right: 30px; }

.sendendform {
	margin-top: 30px;
	/*margin-bottom: 15px;*/
}

.importedBulkLocations {
	float: right;
	padding: 15px 0.75rem 0;
	font-weight: bold;
}

input:not([type]), input[type="text"]:not(.browser-default), input[type="password"]:not(.browser-default), input[type="email"]:not(.browser-default), input[type="url"]:not(.browser-default), input[type="time"]:not(.browser-default), input[type="date"]:not(.browser-default), input[type="datetime"]:not(.browser-default), input[type="datetime-local"]:not(.browser-default), input[type="tel"]:not(.browser-default), input[type="number"]:not(.browser-default), input[type="search"]:not(.browser-default), textarea.materialize-textarea {
	background-color: #FFFFFF;
	border-radius: 4px;
	-moz-border-radius: 4px;
	margin-bottom:30px;
}
/*.input-field label:not(.label-icon).active {
  -webkit-transform: translateY(-18px) scale(0.8);
          transform: translateY(-18px) scale(0.8);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
}*/
input:not([type]):focus:not([readonly]),
input[type=text]:not(.browser-default):focus:not([readonly]),
input[type=password]:not(.browser-default):focus:not([readonly]),
input[type=email]:not(.browser-default):focus:not([readonly]),
input[type=url]:not(.browser-default):focus:not([readonly]),
input[type=time]:not(.browser-default):focus:not([readonly]),
input[type=date]:not(.browser-default):focus:not([readonly]),
input[type=datetime]:not(.browser-default):focus:not([readonly]),
input[type=datetime-local]:not(.browser-default):focus:not([readonly]),
input[type=tel]:not(.browser-default):focus:not([readonly]),
input[type=number]:not(.browser-default):focus:not([readonly]),
input[type=search]:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
  border-bottom: 1px solid #CCCCCC !important;
  -webkit-box-shadow: 0 1px 0 0 #CCCCCC !important;
          box-shadow: 0 1px 0 0 #CCCCCC !important;
}

[type="checkbox"].filled-in:not(:checked) + label::after {
    border: 1px solid;
}
input[type="search"]:not(.browser-default){
	margin: 0px 0px 10px 0px;
}
/*.input-field.col label {
    left: 1.75rem;
}*/
.input-field label {
    color: #939393 !important;
}

.input-field i.password-show {
	margin: 12px 0 0 -40px;
	position: fixed;
}

.input-field {
    margin-top: 0rem;
}

.ratio-editor {
	padding: 0 8px;
}

input[type="text"]:not(.browser-default), input:not([type]), input[type="text"]:not(.browser-default), input[type="password"]:not(.browser-default), input[type="email"]:not(.browser-default), input[type="url"]:not(.browser-default), input[type="time"]:not(.browser-default), input[type="date"]:not(.browser-default), input[type="datetime"]:not(.browser-default), input[type="datetime-local"]:not(.browser-default), input[type="tel"]:not(.browser-default), input[type="number"]:not(.browser-default), input[type="search"]:not(.browser-default), textarea.materialize-textarea {
    padding-left: 15px;
  width: calc(100% - 15px);
}
.inner-addon input#search, input#internalReference, input#accessType, input#downloadSpeed {
  padding-left: 30px;
  width: calc(100% - 30px);
}
.ratiotable div div table.highlight.without-margin-bottom tbody tr td input.delayInputForLead {
	padding-left: 10px !important;
}
.ratiotable div div table.highlight.without-margin-bottom tbody tr td input {
  padding-left: 30px !important;
  width: calc(100% - 30px);
}
.inner-addon input[type="number"]:not(.browser-default) {
  padding-left: 30px;
  width: calc(100% - 30px);
}
.inner-addon input[type="text"]:not(.browser-default) {
  padding-left: 30px;
  width: calc(100% - 30px);
}
.select-wrapper span.caret {
    margin-right: 15px;
}
.browser-default {
    border: 1px solid;
	border-radius: 4px;
	-moz-border-radius: 4px;
}
.footer {
	padding-bottom: 30px;
}
.margin-top-50 {
	margin-top: 50px;
}

.margin-top-20 {
	margin-top: 20px;
}

.unblockable {
	pointer-events: all !important;
}

.height-50px {
	height: 50px;
}
.height-70px {
	height: 70px;
}
.height-90px {
	height: 90px;
}

i.small {
	font-size: 1.8rem !important;
}

.activeStepIcon {
	color: white;
}

.required-sign {
	color: #333;
}

.always-on-top {
	z-index: 9999999;
}

.without-margin-bottom {
	margin-bottom: 0px !important;
}

@media screen and (max-width: 1092px) {
	.locationsList > .sticky-outer-wrapper > .sticky-inner-wrapper {
		position: static !important;
		transform: none !important;
	}
}

.locationsList > .sticky-outer-wrapper > .sticky-inner-wrapper {
	overflow: hidden;
}

#snumberBox {
	position: relative;
}

#snumberBox::before {
	content: "S";
	position: absolute;
	top: 13px;
	left: 27px;
	z-index: 10;
}

#snumberBox > input {
	padding-left: 25px;
}

.button-link {
	padding: 0;
	border: 0;
	background-color: transparent;
}

.button-link:focus {
	background-color: transparent;
}

.bordered {
	padding-left: 15px;
	padding-bottom: 20px !important;
	border: 1px solid;
	border-radius: 4px;
}

.custom-checkbox + label:before {
	border: 1px solid #5a5a5a;
}

.text-danger {
	color: red;
	font-size: 11px;
	font-style: italic;
	margin-left:32px;
}

.downloads-block {
	position: relative;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
}

.downloads-block .downloads-dropdown {
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 69px;
	z-index: 2;
}

.downloads-block .downloads-dropdown button {
	margin-bottom: 4px;
}

.downloads-block .downloads-quote-dropdown {
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 69px;
	z-index: 2;
	margin-right: 350px;
}

.downloads-block .downloads-quote-dropdown button {
	margin-bottom: 4px;
}

.btn-no-margins {
	margin: 0;
}

.section-header {
	padding: 0 0.75rem;
}

.flex-space-between {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.flex-space-between .Forms {
	width: 100%;
}

.flex-space-between-col {
	max-width: 49%;
}

@media (max-width: 601px) {
	.logincontainer {
		max-width: 80%;
		padding: 20px;
	}

	.flex-space-between .flex-space-between-col {
		max-width: 100%;
	}

	#download-offerte-attachment {
		float: left;
	}
}

a.btn.doceri-btn.ratio-btn-locations.disabled {
	color: #d1d1d1 !important;
	text-overflow: ellipsis;
	background-color: #fff !important;
	font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen-Sans","Ubuntu","Cantarell","Helvetica Neue",sans-serif;
}

.ratio-section-title {
	color: #8e8e8e;
}

.ratio-section-title .low-opacity {
	opacity: 0.8;
}

.yielder-fullheight {
	height: 100vh;
}

.provider-select {
	top: -15px !important;
}

.hardware-provider {
	float: right !important;
	top: -10px;
	position: relative;
}

.hardware-provider-overview {
	top: 15px;
	position: relative;
}

.products-overview-btn {
	width: 100%;
	text-align: center;
	margin-bottom: 10px;
}

.questionCheckBox[type="checkbox"]:not(:checked) + label:before,
.questionCheckBox[type="checkbox"]:not(.filled-in) + label:after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 18px;
	height: 18px;
	z-index: 0;
	border: 1px solid #5a5a5a !important;
	border-radius: 4px;
}

div:has(.questions-table) {
	overflow: visible!important;
}