.header-cell-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top: -43px;
    height: 75px;
}

.header-cell-container .header-identifier {
    margin-left: 25px;
    color: #9e9e9e;
}

.header-identifier .check-all-label {
    font-size: 13px;
}

.irma-status-label {
    margin-top: 5px;
    font-size: 13px;
    font-weight: bold;
    color: #9e9e9e;
    text-transform: none;
}

.header-class {
    margin-bottom: 10px;
}

.question-container {
    display: flex;
    flex-direction: column;
}

.question-container .question-error-messages-container {
    display: flex;
    flex-direction: column;
}

.question-container .question-error-messages-container .warning-message {
    color: orange;
}
.question-container .question-error-messages-container .block-message {
    color: red;
}

.question-container .question-error-messages-container .comment-message {
    color: black;
}

.no-upgrade-data-text {
    color: red;
}

.waiting-upgrade-data-text {
    color: orange;
}

.existing-quotations-dialog-header {
    margin-bottom: 20px;
}

.existing-quotations-dialog {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

