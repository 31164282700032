.pagination-wrapper {
  display: flex;
  align-items: center;
}

.pagination-wrapper .pagination-page-wrapper {
  display: flex;
  align-items: center;
}

.pagination-wrapper .pagination-page-wrapper input {
  width: 50px;
  margin: 0 10px;
  height: auto;
  padding: 8px;
}

.pagination-wrapper .pagination-page-wrapper a {
  line-height: 1;
}

.pagination-wrapper .pagination {
  flex: 1;
  margin: 8px;
}

@media (max-width: 767.98px) {
  .pagination-wrapper {
    flex-direction: column;
  }
}
