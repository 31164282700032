@charset "utf-8";
/* CSS Document */

a {
	color: #4c4c4c;
	font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen-Sans","Ubuntu","Cantarell","Helvetica Neue",sans-serif;
}

h1 {
	color: #4c4c4c;
	font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen-Sans","Ubuntu","Cantarell","Helvetica Neue",sans-serif;
}

.lightgrey {
	color: #939393;
}

.blauw {
	color: #00aaff;
}
.geel {
	color:#d9b800
}
.rood {
	color:#DD000D
}

thead {
	background-color:#EDEDED;
}

.overviewkopgroen {
	color: #fff;
	font-size: 1.6em;
	font-weight: 600;
	font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen-Sans","Ubuntu","Cantarell","Helvetica Neue",sans-serif;
}

.additional-discount {
	align-items: center;
	display: flex;
}

.additional-discount input {
	display: flex;
}

.overviewkop {
	flex: auto;
	color: #4c4c4c;
	font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen-Sans","Ubuntu","Cantarell","Helvetica Neue",sans-serif;
}
/* Containers*/

.btn:hover i, .btn-large:hover i {
	color: #fff;
}
.kpn-btn{
	color: #4c4c4c;
	font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen-Sans","Ubuntu","Cantarell","Helvetica Neue",sans-serif;
}

.kpn-btn-white-right i{
	color: #fff;
}

@media (max-width: 993px) {
  	.kpn-btn-steps{
	margin-right: 5px;
}
}

.kpn-btn-steps:hover i{
	color: #fff;
}
a.btn.doceri-btn.kpn-btn-locations.disabled {
	color: #d1d1d1 !important;
	text-overflow: ellipsis;
	background-color: #fff !important;
	font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen-Sans","Ubuntu","Cantarell","Helvetica Neue",sans-serif;
}

.kpn-btn-locations:hover i{
	color: #4c4c4c;
}

/* Forms */
.kpntable div div table.highlight.without-margin-bottom tbody tr td input {
	margin-bottom:0px !important;
	height:30px !important;
	padding-left:15px;
}
input:not([type]), input[type="text"]:not(.browser-default), input[type="password"]:not(.browser-default), input[type="email"]:not(.browser-default), input[type="url"]:not(.browser-default), input[type="time"]:not(.browser-default), input[type="date"]:not(.browser-default), input[type="datetime"]:not(.browser-default), input[type="datetime-local"]:not(.browser-default), input[type="tel"]:not(.browser-default), input[type="number"]:not(.browser-default), input[type="search"]:not(.browser-default), textarea.materialize-textarea {
	border: solid 1px #939393;
}

.browser-default {
    border-color: #939393;
	color: #d1d1d1;
	font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen-Sans","Ubuntu","Cantarell","Helvetica Neue",sans-serif;
}

  /* label color */
   .input-field label {
     color: #000;
   }
   /* label focus color */
   .input-field input[type=text]:focus + label {
     color: #000;
   }
   /* label underline focus color */
   .input-field input[type=text]:focus {
     border-bottom: 1px solid #000;
     box-shadow: 0 1px 0 0 #000;
   }
   /* valid color */
   .input-field input[type=text].valid {
     border-bottom: 1px solid #4CAF50;
     box-shadow: 0 1px 0 0 #4CAF50;
   }
   /* invalid color */
   .input-field input[type=text].invalid {
	 border-bottom: 1px solid #ff0000;
     box-shadow: 0 1px 0 0 #ff0000;
   }
   /* icon prefix focus color */
   .input-field .prefix.active {
     color: #000;
   }

   .autocomplete-content img {
	   display: none !important;
   }

   .autocomplete-content {
	   position: absolute !important;
   }
.select-wrapper span.caret {
    z-index: 1;
	pointer-events: none;
}

.react-autosuggest__container {
	position: relative;
}
.react-autosuggest__suggestions-container {
	position: absolute;
	z-index: 999;
	top: 40px;
}

.autocomplete-item {
	padding:5px;
	background: #FFF;
	border: 1px solid #EEF3FA;
	cursor: pointer;
	min-width: 250px;
}

	.autocomplete-item:hover {
		background: #EEF3FA;
	}

.clickable {
	cursor: pointer
}

.no-spin::-webkit-inner-spin-button, .no-spin::-webkit-outer-spin-button {
	-webkit-appearance: none !important;
	margin: 0 !important;
	-moz-appearance:textfield !important;
}

span.refresh-icon {
	background: url("../images/refresh.gif") no-repeat;
	display: inline-block;
	margin-right: 5px;
	width: 16px;
	height: 16px;
}

tr.totals:last-child {
	background-color: #EDEDED
}


.error-small {
	color: #FF0000;
	font-size: 12px;
}

.preloader-wrapper.small {
	width: 20px;
	height: 20px;
}

.warning-small {
	color: #FF4500;
	font-size: 12px;
}