.terms-and-conditions-container .terms-and-conditions-actions {
    width: 30%;
    margin-bottom: 2%;
}

.terms-and-conditions-container .terms-and-conditions-actions .enable-terms-checkbox {
    width: 30%;
}

.terms-and-conditions-container .terms-and-conditions-actions .enable-terms-checkbox label {
    font-weight: 700;
    font-size: 15px;
}

.terms-and-conditions-container .table-actions {
    font-size: 12px;
}