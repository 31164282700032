.productButton {
    float: none;
    width: 140px;
}

.optionBox {
    border: 2px solid black;
    border-radius: 50px;
    padding-top: 25px !important;
}

.buttonsBox {
    margin-top: 20px;
}