.loader-component {
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
}

.loader-component .overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
}

.loader-component .preloader-wrapper {
  z-index: 1;
}