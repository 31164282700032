.application-tab .Forms .preloader-wrapper {
  width: 30px;
  height: 30px;
  display: flex;
  background-color: transparent !important;
}

.application-tab .Forms .row .application-buttons {
  display: flex;
  justify-content: flex-start
}

.application-tab .doceri-btn {
  margin: 0 10px;
}

.application-tab .application-tab-hardware-buttons {
  display: flex;
  justify-content: space-between;
}

.application-tab .application-hardware-container {
  display: flex;
  width:100%;
}

.application-tab .Forms .input-field {
  width:100%;
}

.application-tab .Forms .category-label {
  margin-bottom: 20px;
  margin-left:20px;
}

.application-tab .Forms .desired-wapp-icon {
  width:25px;
}