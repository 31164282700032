.kpn-dialog .kpn-dialog__footer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: -0.75rem;
}

.kpn-dialog .kpn-dialog__footer > * {
  flex: 1 1 auto;
  padding: 0 0.75rem 0.75rem 0.75rem;
}

.kpn-dialog .kpn-dialog__footer button {
  text-overflow: ellipsis;
  white-space: nowrap;
}
