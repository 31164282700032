.cara-solo-container {
    width: 100%;
    padding: 0;
}

.cara-solo-container .input-field {
    width: 100%;
    padding: 0;
}

.cara-solo-container .crmCaraSoloErrorMessage {
    color: red;
}
