.scroll-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 50% !important;
  transition: all .5s;
}

.scroll-top-button i {
  font-size: 24px !important;
}

.scroll-top-button--hidden {
  opacity: 0;
  bottom: -50px;
}

.dangerStatus {
  color: #FF0000;
}

.progressStatus {
  color: #FFA500;
}

.finishedStatus {
  color: #008000;
}